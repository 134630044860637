.text-small {
    font-size: 10px !important;
}

.sidebar-arrow {
    margin-left: 15px;
    transition: transform 0.3s ease;
}

.open .sidebar-arrow {
    transform: rotate(0deg);
    /* Rotates the arrow to indicate 'open' */
}
/* Main styles for the sidebar */
.left-sidebar {
    background-color: #020f46;
    /* Bootstrap primary blue, or use any blue you prefer */
    color: white;
    /* All text in the sidebar will be white */
    min-height: 100vh;
    /* Ensures the sidebar stretches full height of the viewport */
}

/* Style for links in the sidebar to inherit white text color */
.left-sidebar .sidebar-link,
.left-sidebar .hide-menu {
    color: white;
    /* Ensures links are white */
    transition: background-color 0.3s ease;
    /* Smooth transition for background on hover */
}

/* Enhance visibility for section titles */
.nav-small-cap {
    color: #f8f9fa;
    /* Slightly lighter white for better readability */
    margin-top: 10px;
    /* Adds space above section headers */
    padding: 10px 15px;
    /* Padding for spacing */
}

/* Styling for icons within the sidebar */
.left-sidebar .ti {
    color: white;
    /* Sets all Themify icons to white */
    margin-right: 8px;
    /* Space between icon and text */
}

/* Hover and active state styling for sidebar links */
.left-sidebar .sidebar-link:hover,
.left-sidebar .sidebar-item.open>.sidebar-link,
.left-sidebar .sidebar-item.active>.sidebar-link {
    background-color: #020f46;
    /* A darker blue for hover and active states */
    color: white;
    /* Ensures text remains white */
}

/* Style adjustments for nested list items */
.left-sidebar .sidebar-item ul {
    background-color: #020f46;
    /* Darker blue background for dropdowns */
}

/* Link colors within nested items */
.left-sidebar .sidebar-item ul a {
    color: #cce5ff;
    /* Lighter blue for readability */
    transition: color 0.3s ease;
    /* Smooth transition for color on hover */
}

.left-sidebar .sidebar-item ul a:hover {
    color: white;
    /* White text on hover for better visibility */
}

/* Specific styling for the 'active' state to override other styles if necessary */
.left-sidebar .sidebar-item.active a,
.left-sidebar .sidebar-item.open a {
    color: white;
    /* Ensures active or open links are white */
}

/* Custom styles for logout button to make it stand out */
.left-sidebar .btn-danger {
    width: 100%;
    /* Makes the button full width */
    margin-top: 20px;
    /* Space at the bottom */
    background-color: #dc3545;
    /* Bootstrap danger color for contrast */
    border: none;
    /* Removes the border */
}

.left-sidebar .btn-danger:hover {
    background-color: #c82333;
    /* Darker red on hover */
}

/* Scrollbar styling for modern look and better visibility */
.left-sidebar::-webkit-scrollbar {
    width: 8px;
    /* Narrow width */
}

.left-sidebar::-webkit-scrollbar-thumb {
    background-color: #020f46;
    /* Darker blue for the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners for the scrollbar thumb */
}

.left-sidebar::-webkit-scrollbar-track {
    background-color: #020f46;
    /* Same as sidebar background */
}