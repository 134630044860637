.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-no-gutters {
  margin-right: -15px;
  margin-left: -15px;
}

.custom-no-gutters > .col-md-4 {
  padding-right: 15px;
  padding-left: 15px;
}

html, body{
  font-size: 13px !important;
}

.left-sidebar,
.sidebar-nav,
.brand-logo,
.hide-menu,
.round-16 {
  font-size: 12.5px;
}

.card {
  border-radius: 10px;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-body {
  padding: 30px;
}

.text-center {
  text-align: center;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
}

.card-subtitle {
  font-size: 18px;
  font-weight: 500;
}

.card-content {
  margin-top: 10px;
}

.card-content p {
  margin-bottom: 5px;
}

.card-content h5 {
  margin-bottom: 0;
}

.bg-primary {
  background-color: #031d3a !important;
}

.bg-warning {
  background-color: #4d3f15 !important;
}

.bg-success {
  background-color: #0f461c !important;
}

.bg-danger {
  background-color: #5e0a13 !important;
}

.text-white {
  color: #ffffff !important;
}

.zoom-in {
  transition: transform 0.3s;
}

.zoom-in:hover {
  transform: scale(1.05);
}